import React, { useEffect } from "react"
import { navigate } from "gatsby"
import browserLang from "browser-lang"
import { window } from "browser-monads"


const IndexPage = ({pageContext}) => {

  useEffect(() => {

    if (window.location.hostname === "affiliates.betcenter.be" && window.location.pathname === "/") {
      return navigate(`/affiliate/`);
    }

    if (!pageContext.uid) return null;
    const urlLang = getLanguage();

    let pathItems = window.location.pathname.split("/")
    if (pathItems[1].indexOf(pageLanguages) === -1 ) {
      navigate(`/${urlLang}/${pageContext.uid}`);
    }

  }, []);

  const pageLanguages = (pageContext.alternateLanguages && pageContext.alternateLanguages.length > 0) ? pageContext.alternateLanguages.map(item => item.lang.split("-")[0]) : [pageContext.lang];
  const defaultLanguage = pageContext.lang.split("-")[0];
  const availableLanguages = [...pageLanguages, defaultLanguage];
  const fallbackLanguage = (pageLanguages.indexOf('en') === -1) ? defaultLanguage : 'en';

  const getLanguage = () => browserLang({
    languages: availableLanguages,
    fallback: fallbackLanguage
  });


  return null;
}

export default IndexPage
